import { Link, useNavigate, useRouteError } from '@remix-run/react';

import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  buttonVariants,
} from '@esg-monitor/core';
import { rootUrl } from '@esg-monitor/util-modules';

export function ErrorPage({
  statusCode = 500,
  messages = [
    "We couldn't process your request. We are working on resolving the issue.",
  ],
}: {
  statusCode?: number;
  messages?: Array<string>;
}) {
  const error = useRouteError();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  console.log(error);
  return (
    <div className="flex min-h-screen flex-col items-center gap-10 bg-white pt-16">
      <div className="flex h-full flex-col items-center justify-center text-center">
        <Card className="min-w-[400px] border-t-4 border-t-brand-blue">
          <CardHeader>
            <CardDescription>{statusCode}</CardDescription>
            <CardTitle>Something went wrong</CardTitle>
          </CardHeader>
          <CardContent>{messages}</CardContent>
          <CardContent>{String(error)}</CardContent>
          <CardFooter className="justify-center">
            <Button onClick={goBack} variant={'default'}>
              Go Back
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
