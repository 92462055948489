import { useState } from 'react';

export interface NewsProps {
  title?: string;
  summary: string;
}

export const News = ({ summary, title }: NewsProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="py-4">
      <div className="flex items-center">
        <span className="font-semibold text-xs ml-2">{title ? title : ''}</span>
      </div>
      <div className="bg-gray-100 p-2 rounded-md mt-2 text-sm">
        <div className="text-xs p-2">
          {summary.length > 350 && expanded
            ? summary
            : summary.slice(0, 350) + '...'}
          {summary.length > 350 ? (
            <button
              onClick={() => setExpanded(!expanded)}
              className="text-blue-500 pl-1"
            >
              {expanded ? 'less' : 'more...'}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
