import { Siren } from 'lucide-react';

export interface AlertProps {
  summary: string;
}

export const Alert = ({ summary }: AlertProps) => {
  return (
    <div className="py-4">
      <div className="flex items-center">
        <Siren size={'16'} className="text-red-500" />
        <span className="text-red-500 font-semibold text-xs ml-2">
          Alert Note
        </span>
      </div>
      <div className="rounded border-red-500 border mt-2">
        <div className="text-xs p-2">
          {summary ? summary : 'No alert content'}
        </div>
      </div>
    </div>
  );
};
