import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';

import { Badge } from '@esg-monitor/core';

interface CategoryListProps {
  title: string;
  data: string[];
}

export const CategoryList = ({ data, title }: CategoryListProps) => {
  return (
    <div className="flex gap-2 items-center text-sm my-2">
      <div className="font-bold">{title}</div>
      {data.slice(0, 3).map((item) => (
        <Badge key={item} className="bg-white" variant="outline" size={'lg'}>
          {item}
        </Badge>
      ))}
      <Popover>
        <PopoverTrigger>
          {data.length > 3 && (
            <Badge variant="outline" size={'lg'}>
              +{data.length - 3}
            </Badge>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-col bg-white rounded-md shadow-md px-2 py-1 mt-2">
            {data.slice(3).map((item) => (
              <div
                key={item}
                className="flex justify-between hover:bg-primary/10 px-2 py-1 rounded-md"
              >
                <span className="pr-4">{item}</span>
              </div>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
