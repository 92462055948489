import { formatDistance } from 'date-fns';
import { Link } from 'lucide-react';

import { Avatar, AvatarFallback, AvatarImage } from '@esg-monitor/core';

export interface SourceProps {
  name: string;
  createdAt: string;
  role: string;
  company: string;
  avatar?: string;
}

export const Source = ({
  name,
  createdAt,
  role,
  company,
  avatar,
}: SourceProps) => {
  return (
    <div className="flex items-center">
      <div className="w-12 h-12 bg-border rounded-full flex items-center justify-center flex-none">
        <Avatar>
          <AvatarImage src={avatar} alt={name} />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
      </div>
      <div className="ml-2 text-sm">
        <div className="font-bold">
          {name}
          <span className="ml-1 font-normal text-primary/80">
            {formatDistance(new Date(createdAt), new Date(), {
              addSuffix: true,
            })}
          </span>
        </div>
        <div className="font-light text-primary/80 pt-1">
          {role} @ {company}
        </div>
      </div>
    </div>
  );
};

export interface NewsSourceProps {
  name: string;
  createdAt: string;
  source: string;
  image?: string;
  sourceCountry?: string;
  domain?: string;
}

export const NewsSource = ({
  name,
  createdAt,
  source,
  image,
  domain,
  sourceCountry,
}: NewsSourceProps) => {
  return (
    <div className="flex items-center">
      <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center flex-none">
        <Avatar>
          <AvatarImage src={image} alt={name} />
          <AvatarFallback className="bg-gray-200"></AvatarFallback>
        </Avatar>
      </div>
      <div className="ml-2 text-sm flex justify-between w-full">
        <div className="flex-flex col">
          <div className="font-bold">
            {name} -
            <span className="ml-1 font-normal text-primary/80">
              {formatDistance(new Date(createdAt), new Date(), {
                addSuffix: true,
              })}
            </span>
          </div>
          <div className="font-light text-xs text-primary/80 pt-1">
            {sourceCountry}
          </div>
        </div>
        <a
          href={source}
          target="_blank"
          rel="noreferrer"
          className="text-xs text-blue-500 flex items-center"
        >
          <Link strokeWidth={3} className="text-black mr-1" size={12} />
          Go to post
        </a>
      </div>
    </div>
  );
};
