import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

export interface DevelopmentProps {
  connection?:
    | 'Risk in client operational area'
    | 'Probable client connection'
    | 'Client, project or supplier named';
  title: string;
  summary: string;
}

const warningVariant = cva('w-4 h-4 rounded-full ml-3 mr-1', {
  variants: {
    connection: {
      'Risk in client operational area': 'bg-yellow-400',
      'Probable client connection': 'bg-orange-400',
      'Client, project or supplier named': 'bg-red-400',
    },
  },
});

export const Development = ({
  title,
  summary,
  connection,
}: DevelopmentProps) => {
  return (
    <div className="py-4">
      <div className="flex items-center">
        <span className=" font-semibold text-xs">Topic Development</span>
        {connection && (
          <>
            <div className={cn(warningVariant({ connection }))}></div>
            <span className="text-xs">{connection}</span>
          </>
        )}
      </div>
      <div className="rounded mt-3 text-xs ">
        <div className="font-semibold pb-1">{title}</div>
        <div>{summary}</div>
      </div>
    </div>
  );
};
