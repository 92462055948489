export const sharedDataset = {
  dataset: {
    source: [
      ['Trend', '2017', '2018', '2019', '2020', '2021', '2022'],
      ['Employees', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
      ['Environment', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
      ['Governance', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
      ['Social', 25.2, 37.1, 41.2, 18, 33.9, 49.1],
      ['Contr. Level', 12.2, 15.1, 75.2, 38, 23.9, 15.1],
      ['Contr. Score', 23.2, 36.1, 55.2, 58, 13.9, 40.1],
      ['Risk Percentile', 29.2, 59.1, 23.2, 67, 8.9, 5.1],
    ],
  },
};

export const lineChartOpts = {
  tooltip: {
    trigger: 'axis',
    valueFormatter: (value: unknown) => Math.round(Number(value) * 100) / 100,
    z: 10,
  },
  backgroundColor: '#fff',
  legend: {
    bottom: -3,
  },
  ...sharedDataset,
  xAxis: {
    type: 'category',
    boundaryGap: false,
  },
  yAxis: { gridIndex: 0, boundaryGap: false },
  grid: {
    left: '2%',
    right: '6%',
    bottom: '15%',
    top: '3%',
    containLabel: true,
  },
  toolbox: {
    top: '0',
    right: '0',
    feature: {
      saveAsImage: {},
    },
  },
  series: sharedDataset.dataset.source.map((_, i) => ({
    type: 'line',
    smooth: true,
    seriesLayoutBy: 'row',
    emphasis: { focus: 'series' },
  })),
};

export const pieChartOpts = {
  tooltip: {},
  legend: {
    orient: 'horizontal',
    bottom: 0,
  },
  backgroundColor: '#fff',
  series: [
    {
      name: 'Sentiment Distribution',
      type: 'pie',
      avoidLabelOverlap: false,
      center: ['33%', '50%'],
      radius: ['40%', '75%'],
      label: {
        formatter: '{d}%',
        position: 'inner',
        show: true,
      },
    },
  ],
};

export const barChartOpts = {
  legend: {},
  backgroundColor: '#fff',
  ...sharedDataset,
  xAxis: {
    type: 'category',
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      type: 'bar',
    },
    {
      type: 'bar',
    },
    {
      type: 'bar',
    },
    {
      type: 'bar',
    },
    {
      type: 'bar',
    },
    {
      type: 'bar',
    },
  ],
};
