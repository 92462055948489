import {
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@esg-monitor/core';

const dummyProjects = [
  { value: 'climateAction', label: 'Climate Action' },
  { value: 'cleanEnergy', label: 'Clean Energy' },
  { value: 'sustainableCities', label: 'Sustainable Cities' },
  { value: 'goodHealth', label: 'Good Health' },
  { value: 'qualityEducation', label: 'Quality Education' },
  { value: 'genderEquality', label: 'Gender Equality' },
  { value: 'cleanWater', label: 'Clean Water' },
  { value: 'decentWork', label: 'Decent Work' },
  { value: 'industryInnovation', label: 'Industry Innovation' },
  { value: 'reducedInequalities', label: 'Reduced Inequalities' },
];

const dummyDownloads = [
  {
    reportName: 'Lorem ipsum dolor sit amet',
    dateRequested: 'October 20, 2023',
    size: '40 Mb',
    status: 'Ready to download',
  },
  {
    reportName: 'Consectetur adipiscing elit',
    dateRequested: 'October 21, 2023',
    size: '35 Mb',
    status: 'Pending',
  },
  {
    reportName: 'Sed do eiusmod tempor incididunt',
    dateRequested: 'October 22, 2023',
    size: '45 Mb',
    status: 'In progress',
  },
  {
    reportName: 'Ut labore et dolore magna aliqua',
    dateRequested: 'October 23, 2023',
    size: '50 Mb',
    status: 'Failed',
  },
  {
    reportName: 'Ut enim ad minim veniam',
    dateRequested: 'October 24, 2023',
    size: '38 Mb',
    status: 'Ready to download',
  },
  {
    reportName: 'Quis nostrud exercitation ullamco',
    dateRequested: 'October 25, 2023',
    size: '42 Mb',
    status: 'Ready to download',
  },
  {
    reportName: 'Laboris nisi ut aliquip ex ea commodo consequat',
    dateRequested: 'October 26, 2023',
    size: '37 Mb',
    status: 'Pending',
  },
  {
    reportName: 'Duis aute irure dolor in reprehenderit',
    dateRequested: 'October 27, 2023',
    size: '30 Mb',
    status: 'In progress',
  },
  {
    reportName: 'In voluptate velit esse cillum dolore',
    dateRequested: 'October 28, 2023',
    size: '48 Mb',
    status: 'Failed',
  },
  {
    reportName: 'Eu fugiat nulla pariatur',
    dateRequested: 'October 29, 2023',
    size: '32 Mb',
    status: 'Ready to download',
  },
  {
    reportName: 'Excepteur sint occaecat cupidatat non proident',
    dateRequested: 'October 30, 2023',
    size: '39 Mb',
    status: 'Pending',
  },
  {
    reportName: 'Sunt in culpa qui officia deserunt',
    dateRequested: 'October 31, 2023',
    size: '41 Mb',
    status: 'Ready to download',
  },
];

export const ReportContainer = () => {
  return (
    <Tabs className="w-full" defaultValue="readyToDownload">
      <TabsList className="flex justify-start space-x-1 border-b border-b-gray-300 rounded-none bg-white pb-0">
        <TabsTrigger className="-mb-px" value="projectReportLibrary">
          Project Report Library
        </TabsTrigger>
        <TabsTrigger className="-mb-px" value="customReports">
          Custom reports
        </TabsTrigger>
        <TabsTrigger className="-mb-px" value="readyToDownload">
          Ready to download
        </TabsTrigger>
      </TabsList>

      <TabsContent value="projectReportLibrary" className="mt-2">
        <div className="my-4 flex space-x-2">
          <Input className="max-w-[240px]" placeholder="Search library..." />
          <Select>
            <SelectTrigger className="w-[240px]">
              <SelectValue placeholder="Type" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {dummyProjects.map((project) => (
                  <SelectItem key={project.value} value={project.value}>
                    {project.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {Array.from({ length: 9 }, (_, index) => (
            <div key={index} className="w-full h-48 bg-gray-300 rounded-md" />
          ))}
        </div>
      </TabsContent>

      <TabsContent value="customReports" className="mt-2">
        <div className="my-4 flex space-x-2">
          <Input className="max-w-[240px]" placeholder="Search library..." />
          <Select>
            <SelectTrigger className="w-[240px]">
              <SelectValue placeholder="Type" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {dummyProjects.map((project) => (
                  <SelectItem key={project.value} value={project.value}>
                    {project.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {Array.from({ length: 6 }, (_, index) => (
            <div key={index} className="w-full h-72 bg-gray-300 rounded-md" />
          ))}
        </div>
      </TabsContent>

      <TabsContent value="readyToDownload" className="mt-2">
        <div>
          <div className="grid grid-cols-5 text-primary/50 pt-4 pb-2">
            <div className="col-span-2">Report Name</div>
            <div>Date requested</div>
            <div>Size</div>
            <div>Status</div>
          </div>

          {dummyDownloads.map((download) => (
            <div className="grid grid-cols-5 my-4 bg-gray-300 p-3 rounded-md">
              <div className="col-span-2">{download.reportName}</div>
              <div>{download.dateRequested}</div>
              <div>{download.size}</div>
              <div className="rounded-r-md">{download.status}</div>
            </div>
          ))}
        </div>
      </TabsContent>
    </Tabs>
  );
};
