import { CategoryList } from './components/feed/category-list';
import { Source, SourceProps } from './components/feed/source';
import { Alert, AlertProps } from './components/feed/type-alert';
import {
  Development,
  DevelopmentProps,
} from './components/feed/type-development';
import { Note, NoteProps } from './components/feed/type-note';
import { Report, ReportProps } from './components/feed/type-report';

type ContentProps = AlertProps | NoteProps | DevelopmentProps;

export enum FeedItemType {
  alert = 'alert',
  report = 'report',
  note = 'note',
  development = 'development',
}

export interface FeedItemProps {
  type: keyof typeof FeedItemType;
  user: SourceProps;
  content?: ContentProps;
  attachment?: ReportProps;
  topics: string[];
  projects: string[];
}

const renderContent = ({
  type,
  content,
}: {
  type: keyof typeof FeedItemType;
  content: ContentProps;
}) => {
  switch (type) {
    case 'alert':
      return <Alert {...(content as AlertProps)} />;
    case 'note':
      return <Note {...(content as NoteProps)} />;
    case 'development':
      return <Development {...(content as DevelopmentProps)} />;
    default:
      return null;
  }
};

export const FeedItem = ({
  type = 'development',
  user,
  content,
  topics,
  attachment,
  projects,
}: FeedItemProps) => {
  return (
    <div className="w-full h-full rounded-lg flex flex-col space-y-2 p-4 bg-muted">
      <Source {...user} />
      <div id="content">
        {content && renderContent({ type, content })}
        {type === 'report' && attachment && <Report {...attachment} />}
        {topics.length > 0 && <CategoryList title="Topics" data={topics} />}
        {projects.length > 0 && (
          <CategoryList title="Relevant for" data={projects} />
        )}
      </div>
    </div>
  );
};
