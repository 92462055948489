import { CategoryList } from './components/feed/category-list';
import {
  NewsSource,
  NewsSourceProps,
  Source,
  SourceProps,
} from './components/feed/source';
import { News, NewsProps } from './components/feed/type-news';

interface NewsFeedItemProps {
  user: SourceProps;
  content: NewsProps;
  topics?: string[];
  projects?: string[];
}

export const NewsFeedItem = ({
  user,
  content,
  topics,
  projects,
}: NewsFeedItemProps) => {
  return (
    <div className="w-full flex flex-col rounded-lg bg-gray-50 p-4">
      <Source {...user} />
      <News {...content} />
      {topics && topics.length > 0 && (
        <CategoryList title="Topics" data={topics} />
      )}
      {projects && projects.length > 0 && (
        <CategoryList title="Relevant for" data={projects} />
      )}
    </div>
  );
};

interface NewsMediaItemProps {
  source: NewsSourceProps;
  content: NewsProps;
  topics?: string[];
  projects?: string[];
}

export const NewsMediaItem = ({
  source,
  content,
  topics,
  projects,
}: NewsMediaItemProps) => {
  return (
    <div className="w-full flex flex-col rounded-lg bg-gray-50 p-4">
      <NewsSource {...source} />
      <News {...content} />
      {topics && topics.length > 0 && (
        <CategoryList title="Topics" data={topics} />
      )}
      {projects && projects.length > 0 && (
        <CategoryList title="Relevant for" data={projects} />
      )}
    </div>
  );
};
