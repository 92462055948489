import { ChevronRight } from 'lucide-react';
import { millify } from 'millify';
import Marquee from 'react-fast-marquee';

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Card,
  CardContent,
  CardHeader,
  Link,
} from '@esg-monitor/core';

export type MarqueeCardProps = {
  image?: string;
  name?: string;
  content?: string;
  maxChars: number;
};

const MarqueeCard = ({ image, name, content, maxChars }: MarqueeCardProps) => {
  return (
    <Card className="max-w-[405px] min-h-fit mx-6">
      <CardHeader className="flex flex-row items-center space-x-4 p-8 pb-4">
        <Avatar>
          <AvatarImage className="w-14 h-14 object-cover" src={image} />
          <AvatarFallback>
            <div className="w-14 h-14 rounded-full bg-muted text-primary flex items-center justify-center font-bold">
              {name?.substring(0, 2) ?? 'NA'}
            </div>
          </AvatarFallback>
        </Avatar>
        <div className="text-sm font-bold">{name ?? 'No Data'}</div>
      </CardHeader>
      <CardContent className="p-8 pt-0 prose max-w-none">
        {content
          ? content.length > maxChars
            ? content.slice(0, maxChars) + '...'
            : content
          : 'No Data'}
      </CardContent>
    </Card>
  );
};

export type MarqueeBlockProps = {
  mentions?: number;
  to?: string;
  maxContentChars?: number;
  items?: MarqueeCardProps[];
};

export function MarqueeBlock({
  mentions = 21000,
  to = undefined,
  maxContentChars = 200,
  items = [],
}: MarqueeBlockProps) {
  return (
    // Hardcoded teal colors as per design
    <div className="bg-teal-400 w-full h-full flex flex-col">
      <div className="flex items-center justify-center pt-28 my-2 py-20">
        <h2 className="font-bold text-5xl">
          <span className="text-white">
            {mentions >= 1000
              ? millify(mentions, { lowercase: true }) + '+'
              : mentions > 0
              ? mentions
              : '0'}
          </span>
          <span className="text-teal-800"> Mentions</span>
        </h2>
      </div>
      <Marquee className="min-h-fit">
        {items.length > 1
          ? items
              .slice(Math.floor(items.length / 2), items.length)
              .map((item, i) => (
                <MarqueeCard
                  key={i + 'left'}
                  image={item.image}
                  name={item.name}
                  content={item.content}
                  maxChars={maxContentChars}
                />
              ))
          : Array.from({ length: 10 }).map((_, i) => (
              <MarqueeCard
                key={i + 'left'}
                image={undefined}
                name={'Foo Bar'}
                content={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                }
                maxChars={maxContentChars}
              />
            ))}
      </Marquee>
      <Marquee direction="right" className="min-h-fit pt-12">
        {items.length > 1
          ? items
              .slice(0, Math.floor(items.length / 2))
              .map((item, i) => (
                <MarqueeCard
                  key={i + 'right'}
                  image={item.image}
                  name={item.name}
                  content={item.content}
                  maxChars={maxContentChars}
                />
              ))
          : Array.from({ length: 10 }).map((_, i) => (
              <MarqueeCard
                key={i + 'right'}
                image={undefined}
                name={'Foo Bar'}
                content={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                }
                maxChars={maxContentChars}
              />
            ))}
      </Marquee>
      <div className="pt-20 flex items-center justify-center mb-2 pb-28">
        {to && (
          <Link
            to={to}
            variant={'ghost'}
            className="text-primary-foreground text-sm font-bold"
          >
            <span> See all mentions</span>
            <ChevronRight size={16} />
          </Link>
        )}
      </div>
    </div>
  );
}
