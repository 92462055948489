export interface NoteProps {
  title?: string;
  summary: string;
}

export const Note = ({ summary, title }: NoteProps) => {
  return (
    <div className="py-4">
      <div className="flex items-center">
        <span className="font-semibold text-xs ml-2">
          Note: {title ? title : ''}
        </span>
      </div>
      <div className="rounded border-gray-300 border mt-2">
        <div className="text-xs p-2">{summary}</div>
      </div>
    </div>
  );
};
