import { LinkIcon } from 'lucide-react';

export interface ReportProps {
  summary?: string;
  title: string;
  url: string;
  image?: string;
}

export const Report = ({ summary, title, url, image }: ReportProps) => {
  return (
    <div className="flex py-4">
      {image && (
        <div className="w-1/4 min-h-full h-40 flex-none bg-gray-200 rounded-md overflow-hidden">
          <img
            alt={title}
            className="object-contain object-center w-full"
            src={image}
          />
        </div>
      )}

      <div className="ml-3 rounded-t-md rounded-r-md">
        <div className="text-xs">
          <span className="text-blue-500 font-semibold">
            {/* Should this be a param? */}
            Library PDF Report
          </span>
          <div className="text-light flex mt-1">
            {title ? title : 'untitled'}
            <a
              href={url}
              className="text-primary flex items-center ml-2 hover:underline"
            >
              <LinkIcon size={'12'} className="mr-1" /> Download
            </a>
          </div>
          <div className="mt-2">
            <span className="font-bold mr-2">Summary:</span>
            {summary ? summary : 'No summary content'}
          </div>
        </div>
      </div>
    </div>
  );
};
