import { Form, useSearchParams, useSubmit } from '@remix-run/react';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react';

import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@esg-monitor/core';

export function PaginationBar({ total }: { total: number }) {
  const [searchParams] = useSearchParams();
  const $skip = Number(searchParams.get('$skip')) || 0;
  const $top = Number(searchParams.get('$top')) || 10;

  const totalPages = Math.ceil(total / $top);
  const currentPage = Math.floor($skip / $top) + 1;
  const maxPages = 5;
  const halfMaxPages = Math.floor(maxPages / 2);

  const canPageBackwards = $skip > 0;
  const canPageForwards = $skip + $top < total;

  const pageNumbers = [] as Array<number>;
  if (totalPages <= maxPages) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    let startPage = currentPage - halfMaxPages;
    let endPage = currentPage + halfMaxPages;

    if (startPage < 1) {
      endPage += Math.abs(startPage) + 1;
      startPage = 1;
    }

    if (endPage > totalPages) {
      startPage -= endPage - totalPages;
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  }

  const existingParams = Array.from(searchParams.entries()).filter(([key]) => {
    return key !== '$skip' && key !== '$top';
  });
  const submit = useSubmit();
  return (
    <Form method="GET" className="flex items-center gap-1" preventScrollReset>
      <>
        {[['$top', String($top)], ...existingParams].map(([key, value]) => {
          return <input key={key} type="hidden" name={key} value={value} />;
        })}
      </>
      <div className="flex items-center justify-center px-6 font-medium text-sm text-slate-500 whitespace-nowrap space-x-2">
        <span>Rows per page</span>
        <Select onValueChange={submit} defaultValue={`$top=${$top}`}>
          <SelectTrigger className="">
            <SelectValue placeholder="Rows" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="$top=10">10</SelectItem>
            <SelectItem value="$top=25">25</SelectItem>
            <SelectItem value="$top=50">50</SelectItem>
            <SelectItem value="$top=100">100</SelectItem>
            <SelectItem value="$top=150">150</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="flex items-center justify-center px-8 font-medium text-sm text-slate-500">
        Page {currentPage} of {totalPages}
      </div>
      <Button
        variant="outline"
        size="sm"
        type="submit"
        name="$skip"
        className="text-slate-600 bg-white p-2"
        value="0"
        disabled={!canPageBackwards}
        aria-label="First page"
      >
        <ChevronsLeft size={16} />
      </Button>

      <Button
        variant="outline"
        size="sm"
        type="submit"
        name="$skip"
        className="text-slate-600 bg-white p-2"
        value={Math.max($skip - $top, 0)}
        disabled={!canPageBackwards}
        aria-label="Previous page"
      >
        <ChevronLeft size={16} />
      </Button>

      {/* {pageNumbers.map((pageNumber) => {
        const pageSkip = (pageNumber - 1) * $top;
        const isCurrentPage = pageNumber === currentPage;
        const isValidPage = pageSkip >= 0 && pageSkip < total;

        if (isCurrentPage) {
          return (
            <Button
              variant="ghost"
              size="sm"
              type="submit"
              name="$skip"
              className="min-w-[2rem] bg-slate-200 text-black p-2"
              key={`${pageNumber}-active`}
              value={pageSkip}
              aria-label={`Page ${pageNumber}`}
              disabled={!isValidPage}
            >
              {pageNumber}
            </Button>
          );
        } else {
          return (
            <Button
              variant="ghost"
              size="sm"
              type="submit"
              className="min-w-[2rem] font-normal text-slate-600 p-2"
              name="$skip"
              key={pageNumber}
              value={pageSkip}
              aria-label={`Page ${pageNumber}`}
              disabled={!isValidPage}
            >
              {pageNumber}
            </Button>
          );
        }
      })} */}

      <Button
        variant="outline"
        size="sm"
        type="submit"
        name="$skip"
        className="text-slate-600 bg-white p-2"
        value={Math.min($skip + $top, total - $top + 1)}
        disabled={!canPageForwards}
        aria-label="Next page"
      >
        <ChevronRight size={16} />
      </Button>

      <Button
        variant="outline"
        size="sm"
        type="submit"
        name="$skip"
        className="text-slate-600 bg-white p-2"
        value={(totalPages - 1) * $top}
        disabled={!canPageForwards}
        aria-label="Last page"
      >
        <ChevronsRight size={16} />
      </Button>
    </Form>
  );
}
