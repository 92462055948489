import {
  differenceInCalendarDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  format,
  getWeek,
} from 'date-fns';
import { Selectable } from 'kysely';
import { useState } from 'react';
import { groupBy } from 'remeda';

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@esg-monitor/core';

import { cn } from './utils';

const categories = {
  0: 'Assessment & Management',
  1: 'Labor',
  2: 'Biodiversity',
  3: 'Indigenous & underserved',
  4: 'Resources & pollution',
  5: 'Land Rights',
  6: 'Stakeholder Engagement & Information Disclosure',
  7: 'Cultural Heritage',
  8: 'Financial Intermediaries',
  9: 'Gender',
  10: 'Social Inclusion',
  11: 'Community Health & Safety',
} as const;

type Categories = (typeof categories)[keyof typeof categories];

const riskLevels = {
  direct: 'direct',
  // indirect: 'indirect',
  project: 'project',
  default: 'default',
} as const;

type RiskLevels = (typeof riskLevels)[keyof typeof riskLevels];

export type ProjectEntry = {
  english_category_shorthand: string | null;
  article_date: Date;
  world_bank_connection: string | null;
};

// const generateTimestamps = (
//   amount: number,
//   start: Date,
//   end: Date
// ): string[] => {
//   const timestamps: string[] = [];
//   const startTime = start.getTime();
//   const endTime = end.getTime();

//   if (startTime >= endTime) {
//     throw new Error('Start date must be earlier than end date');
//   }
//   for (let i = 0; i < amount; i++) {
//     const randomTime = startTime + Math.random() * (endTime - startTime);
//     const date = new Date(randomTime);
//     timestamps.push(date.toISOString());
//   }

//   return timestamps;
// };

const generateRandomIndex = <T,>(array: T[]): number => {
  return Math.floor(Math.random() * array.length);
};

// const generateDumyProjectData = (
//   startDate: Date,
//   endDate: Date,
//   amount = 200
// ) => {
//   return generateTimestamps(amount, startDate, endDate).map((timestamp) => ({
//     category: categories[
//       generateRandomIndex(categories)
//     ] as ProjectEntry['category'],
//     timestamp: timestamp,
//     riskLevel: riskLevels[
//       generateRandomIndex(riskLevels)
//     ] as ProjectEntry['riskLevel'],
//   }));
// };

const riskDict: Record<string, Record<string, string>> = {
  default: {
    color: 'bg-orange-500 border border-white',
    label: 'Risk Present',
  },
  direct: {
    color: 'bg-blue-500 border border-white',
    label: 'World Bank Directly Named',
  },
  indirect: {
    color: 'bg-lime-500 border border-white',
    label: 'Probable client connection',
  },
  project: {
    color: 'bg-red-500 border border-white',
    label: 'Project mentioned',
  },
  // 'engagement-opportunity': {
  //   color: 'bg-green-500 border border-white',
  //   label: 'Positive sentiment / Engagement opportunity',
  // },
};

const CircleFilled = ({
  type,
  date,
  content = '',
  category,
}: {
  type: string | null;
  date: Date;
  content?: string;
  category: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const typeDefault = type || 'default';
  return (
    // if type not null
    <HoverCard openDelay={300}>
      <HoverCardTrigger asChild>
        <div
          className={cn(
            'w-3 h-3 rounded-full cursor-pointer ',
            riskDict[typeDefault]?.color
          )}
        ></div>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <div className="flex flex-col items-start">
          <div className="text-base font-semibold">
            {format(date, 'dd MMM yyyy')}
          </div>
          <div className="items-center w-full flex my-1">
            <div
              className={cn(
                'w-3 h-3 rounded-full cursor-pointer flex-none ',
                riskDict[typeDefault]?.color
              )}
            ></div>
            <div className="ml-2 text-xs">{riskDict[typeDefault]?.label}</div>
          </div>
          <div className="text-left">
            <span className="text-sm font-semibold">{category}: </span>
            {content && expanded ? content : content.slice(0, 80) + '...'}
            <button
              onClick={() => setExpanded(true)}
              className={cn(
                'text-blue-500',
                expanded ? 'hidden' : 'inline-block'
              )}
            >
              more...
            </button>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

const CellResolver = ({
  entries,
  interval,
  periodRange,
  category,
}: {
  entries: ProjectEntry[];
  interval: Date;
  periodRange: string;
  category: string;
}) => {
  const filteredEntries = entries.filter((entry) => {
    const entryDate = new Date(entry.article_date);

    switch (periodRange) {
      case 'days':
        return (
          entryDate.getDate() === interval.getDate() &&
          getWeek(entryDate) === getWeek(interval) &&
          entryDate.getMonth() === interval.getMonth() &&
          entryDate.getFullYear() === interval.getFullYear()
        );
      case 'weeks':
        return (
          getWeek(entryDate) === getWeek(interval) &&
          entryDate.getMonth() === interval.getMonth() &&
          entryDate.getFullYear() === interval.getFullYear()
        );
      default:
        return (
          entryDate.getMonth() === interval.getMonth() &&
          entryDate.getFullYear() === interval.getFullYear()
        );
    }
  });
  const oneOfEach = filteredEntries.reduce((agg, project) => {
    if (
      agg.findIndex(
        (x) => x.world_bank_connection === project.world_bank_connection
      ) === -1
    ) {
      agg.push(project);
    }
    return agg;
  }, filteredEntries.slice(0, 0));

  const finalData = periodRange === 'days' ? filteredEntries : oneOfEach;
  return (
    <div className="flex items-center justify-center w-full space-x-0">
      {finalData.map((entry: ProjectEntry, idx: number) => (
        <CircleFilled
          key={idx}
          type={entry.world_bank_connection}
          date={new Date(entry.article_date)}
          category={category}
        />
      ))}
    </div>
  );
};

const dummyStartDate = new Date('2023-11-01T00:00:00Z');
const dummyEndDate = new Date();

export function TableCalendar({
  startDate = dummyStartDate,
  endDate = dummyEndDate,
  dummyAmount = 225,
  projectData = [],
}: {
  startDate?: Date;
  endDate?: Date;
  dummyAmount?: number;
  projectData?: ProjectEntry[];
}) {
  const formattedData = Object.entries(
    groupBy(
      projectData,
      (entry) => entry.english_category_shorthand || 'default'
    )
  ).sort();

  const differenceInDays = differenceInCalendarDays(endDate, startDate);
  const rangeInPeriods =
    differenceInDays > 13 ? 'weeks' : differenceInDays > 60 ? 'months' : 'days';
  const interval =
    rangeInPeriods === 'weeks'
      ? eachWeekOfInterval({ start: startDate, end: endDate })
      : rangeInPeriods === 'months'
      ? eachMonthOfInterval({ start: startDate, end: endDate })
      : eachDayOfInterval({ start: startDate, end: endDate });

  // console.log('>>>>>>>>>', JSON.stringify(formattedData, null, 2));

  return (
    <div>
      <Table>
        <TableBody>
          {formattedData.map(([category, entries]) => (
            <TableRow
              className="odd:bg-slate-100 rounded overflow-hidden border-none odd:hover:bg-slate-100 hover:bg-white"
              key={category}
            >
              <TableCell
                className="rounded-l overflow-hidden text-xs text-primary/70"
                width={'310px'}
              >
                {category}
              </TableCell>
              {interval.map((interval) => {
                return (
                  <TableCell
                    key={'' + interval}
                    className="text-center last:rounded-r overflow-hidden"
                  >
                    <CellResolver
                      entries={entries}
                      periodRange={rangeInPeriods}
                      interval={interval}
                      category={category}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          <TableRow>
            <TableCell></TableCell>
            {interval.map((date, idx) => (
              <TableCell
                key={'date' + idx}
                className="text-center text-[8px] text-primary/80 p-1 bg-white hover:bg-white leading-tight"
              >
                {format(
                  date,
                  rangeInPeriods === 'weeks'
                    ? 'MM-dd-yyyy'
                    : rangeInPeriods === 'months'
                    ? 'MM-yyyy'
                    : 'MM-dd-yyyy'
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <div className="pt-12">
        <div className="flex items-center space-x-6">
          {Object.keys(riskLevels).map((riskLevel) => (
            <div
              key={'risk' + riskLevel}
              className="flex items-center space-x-2 whitespace-nowrap"
            >
              <div
                className={cn(
                  'w-4 h-4 rounded-full cursor-pointer flex-none ',
                  riskDict[riskLevel]?.color
                )}
              ></div>
              <div className="text-sm text-slate-600">
                {riskDict[riskLevel]?.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
