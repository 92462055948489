import { Badge, Input } from '@esg-monitor/core';

interface FilterProps {
  title: string;
  data: { value: string; label: string }[];
}

// Infuential Actors

export const Filter = ({ title, data }: FilterProps) => {
  return (
    <div className="grid grid-cols-12  items-center space-x-2">
      <div className="text-sm self-start py-1 col-span-2 flex items-center h-8">
        <span className="font-bold">{title}</span>
      </div>
      <div className="flex flex-wrap gap-2 col-span-10">
        {data.map((item, i) => (
          <Badge key={i} variant={'outline'} size={'lg'}>
            <span className="truncate">
              {item.label.substring(0, 30)}
              {item.label.length > 30 && '...'}
            </span>
          </Badge>
        ))}
      </div>
    </div>
  );
};

export const FeedFilters = ({
  topicsData,
  actorsData,
}: {
  topicsData?: { value: string; label: string }[];
  actorsData?: { value: string; label: string }[];
}) => {
  return (
    <div className="w-full h-full rounded-md flex flex-col gap-y-4 p-6 bg-white">
      <div className="w-full flex justify-between items-center">
        <h2>
          <span className="font-bold">Analyst's Updates</span>{' '}
          <span>- 5 projects</span>
        </h2>
        <div className="max-w-xs w-full">
          <Input placeholder={'Search...'}></Input>
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        {topicsData && topicsData?.length > 0 && (
          <Filter title={'Topics'} data={topicsData} />
        )}
        {actorsData && actorsData?.length > 0 && (
          <Filter title={'Influential Actors'} data={actorsData} />
        )}
      </div>
    </div>
  );
};
