import { cva } from 'class-variance-authority';

import { cn } from './utils';
import logo from '../images/ipsos-logo.png';

interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: 'sm' | 'md' | 'lg';
}

const sizeVariants = cva('', {
  variants: {
    size: {
      sm: 'h-8 w-8',
      md: 'h-10 w-auto',
      lg: 'h-16 w-16',
    },
  },
});

export function Logo({ className, size = 'md', ...props }: LogoProps) {
  return (
    <img
      alt="Ipsos GRASP"
      className={cn(sizeVariants({ size, className }))}
      src={logo}
      {...props}
    />
  );
}
