export const dummySlides = [
  {
    name: 'Mobile Tech Insights',
    source: 'Twitter / @mobiletechinsights',
    createdAt: '2023-11-20T12:45:00.000Z',
    content:
      'Phasellus eget enim eu eros faucibus auctor. Duis nec neque ut est fermentum aliquet sed vitae turpis. Fusce condimentum et felis sit amet sodales.Phasellus eget enim eu eros faucibus auctor. Duis nec neque ut est fermentum aliquet sed vitae turpis. Fusce condimentum et felis sit amet sodales.',
    affected: '2 topics',
    relevant: '1 project',
  },
  {
    name: 'Data Science Community',
    source: 'Facebook / @datasciencecommunity',
    createdAt: '2023-11-20T09:00:00.000Z',
    content:
      'Mauris luctus dui vel mollis consequat. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor.Mauris luctus dui vel mollis consequat. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor.',
    affected: '6 topics',
    relevant: '3 projects',
  },
  {
    name: 'Renewable Resources Report',
    source: 'Instagram / @renewableresourcesreport',
    createdAt: '2023-11-19T22:15:00.000Z',
    content:
      'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec vel orci sapien. Vivamus et elit massa.Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec vel orci sapien. Vivamus et elit massa.',
    affected: '4 topics',
    relevant: '2 projects',
  },
  {
    name: 'Future Cities Digest',
    source: 'LinkedIn / @futurecitiesdigest',
    createdAt: '2023-11-19T20:30:00.000Z',
    content:
      'Donec non est at libero viverra vehicula. Ut sodales dolor quis arcu adipiscing, ac rhoncus ipsum commodo. Sed ut risus lobortis.',
    affected: '7 topics',
    relevant: '6 projects',
  },
  {
    name: 'AI Development Times',
    source: 'Twitter / @AIDevelopmentTimes',
    createdAt: '2023-11-19T18:05:00.000Z',
    content:
      'Pellentesque viverra, mauris vel tincidunt blandit, neque enim pulvinar ante, non aliquet sem lorem non nisi. Cras consequat sed eros at tincidunt.Pellentesque viverra, mauris vel tincidunt blandit, neque enim pulvinar ante, non aliquet sem lorem non nisi. Cras consequat sed eros at tincidunt.Pellentesque viverra, mauris vel tincidunt blandit, neque enim pulvinar ante, non aliquet sem lorem non nisi. Cras consequat sed eros at tincidunt.',
    affected: '5 topics',
    relevant: '4 projects',
  },
  {
    name: 'Space Exploration Hub',
    source: 'Facebook / @spaceexplorationhub',
    createdAt: '2023-11-19T16:20:00.000Z',
    content:
      'Integer in aliquet nisl. Vivamus semper justo non justo efficitur, non tincidunt nunc elementum. Ut sed facilisis magna.Integer in aliquet nisl. Vivamus semper justo non justo efficitur, non tincidunt nunc elementum. Ut sed facilisis magna.Integer in aliquet nisl. Vivamus semper justo non justo efficitur, non tincidunt nunc elementum. Ut sed facilisis magna.',
    affected: '3 topics',
    relevant: '1 project',
  },
  {
    name: 'Financial Market Watch',
    source: 'Instagram / @financialmarketwatch',
    createdAt: '2023-11-19T12:50:00.000Z',
    content:
      'Aenean augue purus, vehicula at enim vel, commodo hendrerit velit. Fusce vitae justo vitae arcu auctor ultrices nec a massa.Aenean augue purus, vehicula at enim vel, commodo hendrerit velit. Fusce vitae justo vitae arcu auctor ultrices nec a massa.Aenean augue purus, vehicula at enim vel, commodo hendrerit velit. Fusce vitae justo vitae arcu auctor ultrices nec a massa.',
    affected: '6 topics',
    relevant: '5 projects',
  },
  {
    name: 'Health Max Insights',
    source: 'LinkedIn / @healthmaxinsights',
    createdAt: '2023-11-19T11:15:00.000Z',
    content:
      'Quisque euismod, augue in porta aliquam, orci neque scelerisque dolor, sed placerat odio risus quis nisl. Nulla facilisi.Quisque euismod, augue in porta aliquam, orci neque scelerisque dolor, sed placerat odio risus quis nisl. Nulla facilisi.Quisque euismod, augue in porta aliquam, orci neque scelerisque dolor, sed placerat odio risus quis nisl. Nulla facilisi.',
    affected: '4 topics',
    relevant: '3 projects',
  },
  {
    name: 'Eco Innovations News',
    source: 'Facebook / @ecoinnovationsnews',
    createdAt: '2023-11-19T08:45:00.000Z',
    content:
      'Curabitur sit amet est scelerisque, fermentum quam nec, aliquam ligula. Aliquam erat volutpat. Morbi tincidunt non lorem vitae convallis.Curabitur sit amet est scelerisque, fermentum quam nec, aliquam ligula. Aliquam erat volutpat. Morbi tincidunt non lorem vitae convallis.Curabitur sit amet est scelerisque, fermentum quam nec, aliquam ligula. Aliquam erat volutpat. Morbi tincidunt non lorem vitae convallis.',
    affected: '3 topics',
    relevant: '2 projects',
  },
  {
    name: 'Global Tech Updates',
    source: 'Twitter / @globaltechupdates',
    createdAt: '2023-11-19T05:30:00.000Z',
    content:
      'Suspendisse potenti. Praesent volutpat velit vitae est sollicitudin, et ultrices neque vehicula. Maecenas ac imperdiet libero, sed ullamcorper ex.Suspendisse potenti. Praesent volutpat velit vitae est sollicitudin, et ultrices neque vehicula. Maecenas ac imperdiet libero, sed ullamcorper ex.Suspendisse potenti. Praesent volutpat velit vitae est sollicitudin, et ultrices neque vehicula. Maecenas ac imperdiet libero, sed ullamcorper ex.',
    affected: '2 topics',
    relevant: '1 project',
  },
  {
    name: 'Global Tech Updates',
    source: 'Twitter / @globaltechupdates',
    createdAt: '2023-11-19T05:30:00.000Z',
    content:
      'Suspendisse potenti. Praesent volutpat velit vitae est sollicitudin, et ultrices neque vehicula. Maecenas ac imperdiet libero, sed ullamcorper ex.Suspendisse potenti. Praesent volutpat velit vitae est sollicitudin, et ultrices neque vehicula. Maecenas ac imperdiet libero, sed ullamcorper ex.Suspendisse potenti. Praesent volutpat velit vitae est sollicitudin, et ultrices neque vehicula. Maecenas ac imperdiet libero, sed ullamcorper ex.',
    affected: '2 topics',
    relevant: '1 project',
  },
];

export const dummyDevelopmentSlides = [
  {
    timestamp: '2023-11-19T02:00:00.000Z',
    topic: 'Climate Change',
    risk: 'direct',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aute irure dolor.',
  },
  {
    timestamp: '2023-11-19T04:15:00.000Z',
    topic: 'Data Privacy',
    risk: 'indirect-association',
    content:
      'Aenean lacinia bibendum nulla sed consectetur. Aenean leo quam venenatisAenean lacinia bibendum nulla sed consectetur. Aenean leo quam venenatisAenean lacinia bibendum nulla sed consectetur. Aenean leo quam venenatisAenean lacinia bibendum nulla sed consectetur. Aenean leo quam venenatis.',
  },
  {
    timestamp: '2023-11-19T06:30:00.000Z',
    topic: 'Worker Safety',
    risk: 'indirect-potential',
    content:
      'Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio.Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio.Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio.Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio.',
  },
  {
    timestamp: '2023-11-19T08:45:00.000Z',
    topic: 'Sustainable Sourcing',
    risk: 'indirect-potential',
    content:
      'Maecenas sed diam eget risus varius blandit sit amet non magna.Maecenas sed diam eget risus varius blandit sit amet non magna.Maecenas sed diam eget risus varius blandit sit amet non magna.Maecenas sed diam eget risus varius blandit sit amet non magna.',
  },
  {
    timestamp: '2023-11-19T11:00:00.000Z',
    topic: 'Diversity and Inclusion',
    risk: 'direct',
    content:
      'Nullam quis risus eget urna mollis ornare. Lorem ipsum dolor sit.Nullam quis risus eget urna mollis ornare. Lorem ipsum dolor sit.Nullam quis risus eget urna mollis ornare. Lorem ipsum dolor sit.Nullam quis risus eget urna mollis ornare. Lorem ipsum dolor sit.vNullam quis risus eget urna mollis ornare. Lorem ipsum dolor sit.',
  },
];
