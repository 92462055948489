import { eachDayOfInterval, endOfYear, format, startOfYear } from 'date-fns';
import type { EChartsReactProps } from 'echarts-for-react';
import { Suspense, lazy } from 'react';

// using new suspense React Lazy to load echarts-for-react
// otherwise it sometimes loads serverside and fails
const ReactECharts = lazy(() => import('echarts-for-react'));

// rewritten using date-fns instead of echarts.time
function getVirtualData(year: string) {
  const start = startOfYear(new Date(year));
  const end = endOfYear(new Date(year));
  const dayRange = eachDayOfInterval({ start, end });
  const data: [string, number][] = [];

  for (const day of dayRange) {
    data.push([format(day, 'yyyy-MM-dd'), Math.floor(Math.random() * 100)]);
  }
  return data;
}

export const heatmapChartOpts = {
  title: {
    top: 30,
    left: 'center',
    text: 'Daily Change of ESG Score',
  },
  gradientColor: [
    '#cdffb2',
    '#bbf5af',
    '#a9eaad',
    '#97e0ab',
    '#83d5a8',
    '#6fcba6',
    '#58c1a3',
    '#3cb7a1',
    '#00ad9e',
  ],
  backgroundColor: '#fff',
  tooltip: {},
  visualMap: {
    min: 0,
    max: 100,
    type: 'piecewise',
    orient: 'horizontal',
    left: 'center',
    top: 65,
  },
  calendar: {
    top: 120,
    left: 30,
    right: 30,
    cellSize: ['auto', 13],
    range: '2022',
    itemStyle: {
      borderWidth: 0.5,
    },
    yearLabel: { show: false },
  },
  series: {
    type: 'heatmap',
    coordinateSystem: 'calendar',
    data: getVirtualData('2022'),
  },
};

const Loader = () => <div>Loading...</div>;

export function ClientSideEcharts(props: EChartsReactProps) {
  return (
    <Suspense fallback={<Loader />}>
      <ReactECharts
        {...props}
        opts={{ renderer: 'svg' }}
        notMerge={true}
        lazyUpdate={true}
      />
    </Suspense>
  );
}
